import styled from '@emotion/styled';
import { CashInputsValues } from '../NewOperationForm';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { FunctionComponent } from 'react';
import { isDefined } from '@sgme/fp';

interface OperationProps {
  isActionSuccessful: boolean;
  operation: CashInputsValues;
  onClickHandle: (operation: CashInputsValues) => void;
}

export function CashOperation({ isActionSuccessful, operation, onClickHandle }: OperationProps) {
  return (
    <StyledOperation
      className="d-flex justify-content-between align-items-center p-3 m-2"
      style={{ maxWidth: '1240px' }}
    >
      <div className="d-flex align-items-center p-3 pl-4 pr-5 pt-2 fs-5">
        {isActionSuccessful ? (
          <OperationBadge labelId="HedgeMapping.Wallet" type="success" />
        ) : (
          <OperationBadge labelId="HedgeMapping.Process" type="info" />
        )}
      </div>

      <div className="d-flex justify-content-between w-75">
        <OperationColumn labelId="HedgeElement.Product" value={operation.product} />
        <OperationColumn
          labelId="HedgeElement.Buy"
          value={operation.side === 'Buy' ? operation.amount : undefined}
          suffix={operation.buyCurrency}
        />
        <OperationColumn
          labelId="HedgeElement.Sell"
          value={operation.side === 'Sell' ? operation.amount : undefined}
          suffix={operation.sellCurrency}
        />
        <OperationColumn labelId="HedgeElement.Deadline" value={operation.settlement} />
        {operation?.sgReference && <OperationColumn labelId="HedgeElement.sgReference" value={operation?.sgReference} />}

        {operation.rate && <OperationColumn labelId="HedgeElement.Rate" value={operation.rate} />}
      </div>

      <div className="pr-5">
        <button
          className="btn btn-lg btn-flat-secondary mr-4 pr-4"
          onClick={() => onClickHandle(operation)}
        >
          <i className="icon icon-xl">delete</i>
        </button>
      </div>
    </StyledOperation>
  );
}

const StyledOperation = styled.div`
  background-color: #f5f7fa;
  border: 1px solid #7185b3;
`;

const OperationLabel = styled.div`
  color: #808080;
`;

const OperationRate = styled.div`
  color: #47619d;
`;

const StyledOperationBadge = styled.span`
  background-color: #ffffff;
`;

interface OperationBadgeProps {
  labelId: string;
  type: 'success' | 'info';
}

const OperationBadge: FunctionComponent<OperationBadgeProps> = ({ labelId, type }) => {
  return (
    <StyledOperationBadge
      className={`badge ${type === 'success' ? 'badge-outline-success' : 'badge-outline-info'}`}
    >
      <FormattedMessage id={labelId} />
    </StyledOperationBadge>
  );
};

interface OperationColumnProps {
  labelId: string;
  value: string | number | undefined;
  suffix?: string;

  className?: string;
}

const OperationColumn: FunctionComponent<OperationColumnProps> = ({
  labelId,
  value,
  suffix,
  className,
}) => {
  const numberValue = typeof value === 'number' ? value : undefined;

  return (
    <div className={`w-25 ${className}`}>
      <OperationLabel>
        <FormattedMessage id={labelId} />
      </OperationLabel>

      <div className="mt-2">
        {isDefined(numberValue) ? <FormattedNumber value={numberValue} /> : value} {suffix}
      </div>
    </div>
  );
};
