import { addBusinessDays, format } from 'date-fns';
import { CashInputsValues, SwapInputsValues } from '../Component/Operation/NewOperationForm';

export function datePlusTwo() {
  return format(addBusinessDays(new Date(), 2), 'yyyy-MM-dd');
}

export const legMock: (CashInputsValues | SwapInputsValues)[] = [
  {
    thirdPartyTradeReference: 'TMS_dazidlnkaefq',
    product: 'Spot',
    buyCurrency: 'EUR',
    sellCurrency: 'USD',
    side: 'Buy',
    amount: 100000,
    settlement: datePlusTwo(),
  },
  {
    thirdPartyTradeReference: 'TMS_dazdlnkaefqs',
    product: 'Forward',
    buyCurrency: 'EUR',
    sellCurrency: 'USD',
    side: 'Sell',
    amount: 35000,
    settlement: format(addBusinessDays(new Date(), 20), 'yyyy-MM-dd'),
    sgReference: 'Hey, I\'m a forward'
  },
  {
    thirdPartyTradeReference: 'TMS_dazdlnkaefqg',
    product: 'Forward',
    buyCurrency: 'EUR',
    sellCurrency: 'USD',
    side: 'Sell',
    amount: 50000,
    settlement: format(addBusinessDays(new Date(), 40), 'yyyy-MM-dd'),
  },
];
