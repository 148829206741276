import { FC, PropsWithChildren, useCallback, useId, useState } from 'react';
import { Collapse } from 'reactstrap';

interface CollapsibleSectionProps {
  title: string;
  defaultIsOpened?: boolean;
}

export const CollapsibleSection: FC<PropsWithChildren<CollapsibleSectionProps>> = ({
  title,
  defaultIsOpened = false,
  children,
}) => {
  const [isOpened, setIsOpened] = useState(defaultIsOpened);

  const toggle = () => {
    setIsOpened(isOpened => !isOpened);
  };

  return (
    <div className="sgbs-collapse-separator sgbs-collapse-separator-centered">
      <div className="card">
        <div className="pl-0 card-header">
          <button
            className={`btn btn-flat-primary btn-block justify-content-center${
              !isOpened ? ' collapsed' : ''
            }`}
            onClick={toggle}
            data-toggle="collapse"
            data-target="#separatorOne"
            aria-expanded="false"
            aria-controls="separatorOne"
          >
            <span>
              {title}
              <em className="icon icon-sm"> keyboard_arrow_down </em>
            </span>
          </button>
        </div>

        <Collapse isOpen={isOpened}>
          <div className="card-body pt-0">{children}</div>
        </Collapse>
      </div>
    </div>
  );
};
