import { noop } from '@sgme/fp';
import { createSafeContext } from '@sgme/react';
import type { Dispatch, SetStateAction } from 'react';

export type BooleanUpdater = Dispatch<SetStateAction<boolean>>;

export const [IsAckOnProductPricedProvider, useIsAckOnProductPriced] =
  createSafeContext<boolean>('isAckOnProductPriced');
export const [SetIsAckOnProductPricedProvider, useSetIsAckOnProductPriced] =
  createSafeContext<BooleanUpdater>('setIsAckOnProductPriced', noop);
export const [IsIncompleteConfigurationProvider, useIsIncompleteConfiguration] =
  createSafeContext<boolean>('isIncompleteConfiguration');
export const [SetIsIncompleteConfigurationProvider, useSetIsIncompleteConfiguration] =
  createSafeContext<BooleanUpdater>('setIsIncompleteConfiguration', noop);
