import Header from './Component/Layout/Header';
import { OperationList } from './Component/Operation/OperationList';
import { IntlProvider } from './context/intlContext';
import { useState } from 'react';
import {
  IsAckOnProductPricedProvider,
  SetIsAckOnProductPricedProvider,
  IsIncompleteConfigurationProvider,
  SetIsIncompleteConfigurationProvider,
} from './context/contexts';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'hedgeconnect-smartbutton': any;
    }
  }
}

function App() {
  const [isAckOnProductPricedEnabled, setIsAckOnProductPricedEnabled] = useState(true);
  const [isIncompleteConfigurationEnabled, setIsIncompleteConfigurationEnabled] = useState(false);

  return (
    <IsAckOnProductPricedProvider value={isAckOnProductPricedEnabled}>
      <SetIsAckOnProductPricedProvider value={setIsAckOnProductPricedEnabled}>
        <IsIncompleteConfigurationProvider value={isIncompleteConfigurationEnabled}>
          <SetIsIncompleteConfigurationProvider value={setIsIncompleteConfigurationEnabled}>
            <IntlProvider>
              <div className="App">
                <Header />
                <OperationList />
              </div>
            </IntlProvider>
          </SetIsIncompleteConfigurationProvider>
        </IsIncompleteConfigurationProvider>
      </SetIsAckOnProductPricedProvider>
    </IsAckOnProductPricedProvider>
  );
}

export default App;
