import { FormattedMessage } from 'react-intl';
import { SwapInputsValues } from '../NewOperationForm';

interface SwapProps {
  swap: SwapInputsValues;
  onClickHandle: (swap: SwapInputsValues) => void;
}

export function SwapOperation({ swap, onClickHandle }: SwapProps) {
  return (
    <div
      className="d-flex justify-content-between operation p-3 m-2"
      style={{ maxWidth: '1240px' }}
    >
      <div className="pl-4 pt-2 fs-5">
        <span className="badge badge-outline-info">A traiter</span>
      </div>
      <div className="d-flex flex-column justify-content-between w-75">
        <div className="d-flex justify-content-between">
          <div className="pl-5 w-25">
            <p className="operation__title pt-1">
              <FormattedMessage id="HedgeElement.Product" />
            </p>
            <h6 className="pb-2">{swap.nearLeg.product}</h6>
          </div>
          <div className="w-25">
            <p className="operation__title pt-1">
              <FormattedMessage id="HedgeElement.Buy" />
            </p>
            <h6 className="pb-2">
              {swap.nearLeg.side === 'Buy' ? swap.nearLeg.amount : ''} {swap.nearLeg.buyCurrency}
            </h6>
          </div>
          <div className="w-25">
            <p className="operation__title pt-1">
              <FormattedMessage id="HedgeElement.Sell" />
            </p>
            <h6 className="pb-2">
              {swap.nearLeg.side === 'Sell' ? swap.nearLeg.amount : ''} {swap.nearLeg.sellCurrency}
            </h6>
          </div>
          <div className="w-25">
            <p className="operation__title pt-1">
              <FormattedMessage id="HedgeElement.Deadline" />
            </p>
            <h6 className="pb-2"> {swap.farLeg.settlement}</h6>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="align-item-center pl-5 w-25">
            <p className="operation__title pt-1">
              <FormattedMessage id="HedgeElement.Product" />
            </p>
            <h6 className="pb-2">{swap.farLeg.product}</h6>
          </div>
          <div className="w-25">
            <p className="operation__title pt-1">
              <FormattedMessage id="HedgeElement.Buy" />
            </p>
            <h6 className="pb-2">
              {swap.nearLeg.side === 'Buy' ? swap.nearLeg.amount : ''} {swap.nearLeg.buyCurrency}
            </h6>
          </div>
          <div className="w-25">
            <p className="operation__title pt-1">
              <FormattedMessage id="HedgeElement.Sell" />
            </p>
            <h6 className="pb-2">
              {swap.nearLeg.side === 'Sell' ? swap.nearLeg.amount : ''} {swap.nearLeg.sellCurrency}
            </h6>
          </div>

          <div className="w-25">
            <p className="operation__title pt-1">
              <FormattedMessage id="HedgeElement.Deadline" />
            </p>
            <h6 className="pb-2"> {swap.farLeg.settlement}</h6>
          </div>
        </div>
      </div>

      <button className="btn btn-lg btn-flat-primary mr-5 pr-5" onClick={() => onClickHandle(swap)}>
        <i className="icon icon-xl">delete</i>
      </button>
    </div>
  );
}
