import React, { useCallback } from 'react';
import { datePlusTwo } from '../../../mock/mock';
import { FormattedMessage } from 'react-intl';
import type { CashInputsValues } from '../NewOperationForm';

interface OperationFormProps {
  inputsValues: CashInputsValues;
  setFormValues: (values: CashInputsValues) => void;
}

type CashInput =
  | 'product'
  | 'buyCurrency'
  | 'sellCurrency'
  | 'side'
  | 'amount'
  | 'settlement'
  | 'sgReference';

export function NewCashForm({ inputsValues, setFormValues }: OperationFormProps) {
  const onChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>, input: CashInput) => {
      const inputValue = event.target.value;

      if (input === 'amount') {
        const amount = parseFloat(inputValue).toFixed(2);
        const changedValues = { ...inputsValues, amount: parseFloat(amount) };

        setFormValues(changedValues);
      } else {
        const changedValues = { ...inputsValues, [input]: inputValue };
        setFormValues(changedValues);
      }
    },
    [inputsValues],
  );

  return (
    <div className="form-group">
      <label className="pl-5 ">
        <FormattedMessage id="HedgeCover.Product" />
      </label>
      <select
        className="form-control ml-5 w-75"
        onChange={event => onChangeHandler(event, 'product')}
      >
        <FormattedMessage id="HedgeCover.Spot">
          {option => <option value="Spot">{option}</option>}
        </FormattedMessage>
        <FormattedMessage id="HedgeCover.Forward">
          {option => <option value="Forward">{option}</option>}
        </FormattedMessage>
      </select>
      <div>
        <label className="pl-5" htmlFor="devises">
          <FormattedMessage id="HedgeCover.BuyCurrency" />
        </label>
        <input
          className="form-control ml-5 w-75"
          placeholder="devises"
          onChange={event => onChangeHandler(event, 'buyCurrency')}
          required
        />
      </div>
      <div>
        <label className="pl-5" htmlFor="devises">
          <FormattedMessage id="HedgeCover.SellCurrency" />
        </label>
        <input
          className="form-control ml-5 w-75"
          placeholder="devises"
          onChange={event => onChangeHandler(event, 'sellCurrency')}
          required
        />
      </div>
      <label className="pl-5">
        <FormattedMessage id="HedgeCover.Side" />
      </label>
      <select
        className="form-control ml-5 w-75"
        defaultValue={inputsValues.side}
        onChange={event => onChangeHandler(event, 'side')}
      >
        <FormattedMessage id="HedgeCover.Buy">
          {option => <option value="Buy">{option}</option>}
        </FormattedMessage>
        <FormattedMessage id="HedgeCover.Sell">
          {option => <option value="Sell">{option}</option>}
        </FormattedMessage>
      </select>
      <label className="pl-5" htmlFor="montant">
        <FormattedMessage id="HedgeCover.Amount" />
      </label>
      <input
        className="form-control ml-5 w-75"
        placeholder="montant"
        onChange={event => onChangeHandler(event, 'amount')}
        required
      />

      <label className="pl-5" htmlFor="montant">
        <FormattedMessage id="HedgeCover.Deadline" />
      </label>
      <input
        type="date"
        defaultValue={datePlusTwo()}
        className="form-control ml-5 w-75"
        placeholder="Echéance"
        onChange={event => onChangeHandler(event, 'settlement')}
      />
      <label className="pl-5" htmlFor="Reference">
        <FormattedMessage id="HedgeCover.sgReference" />
      </label>
      <input
        className="form-control ml-5 w-75"
        placeholder="Reference"
        onChange={event => onChangeHandler(event, 'sgReference')}
      />
    </div>
  );
}
