import { type MouseEvent, useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';
import {
  type BooleanUpdater,
  useIsAckOnProductPriced,
  useIsIncompleteConfiguration,
  useSetIsAckOnProductPriced,
  useSetIsIncompleteConfiguration,
} from '@/context/contexts';

function stopPropagationAndFlip(updater: BooleanUpdater) {
  return (event: MouseEvent) => {
    event.stopPropagation();
    updater(value => !value);
  };
}

function Header() {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const toggleMenuVisibility = () => setIsMenuOpened(prevState => !prevState);

  const isAckOnProductPricedEnabled = useIsAckOnProductPriced();
  const setIsAckOnProductPricedEnabled = useSetIsAckOnProductPriced();

  const isIncompleteConfigurationEnabled = useIsIncompleteConfiguration();
  const setIsIncompleteConfigurationEnabled = useSetIsIncompleteConfiguration();

  return (
    <div
      className="d-flex flex-between h-100 mx-auto menu-container mt-4"
      style={{ maxWidth: '1220px' }}
    >
      <Dropdown isOpen={isMenuOpened} toggle={toggleMenuVisibility} direction="down">
        <DropdownToggle className="btn btn-flat-black btn-lg border-0">
          <HeaderIcon className="icon icon-lg ">dehaze</HeaderIcon>
        </DropdownToggle>

        <DropdownMenu>
          <DropdownItem>
            <FormGroup check onClick={stopPropagationAndFlip(setIsAckOnProductPricedEnabled)}>
              <Input type="checkbox" checked={isAckOnProductPricedEnabled} />

              <Label check>
                <FormattedMessage id="HedgeElements.menu.ackOnProductPriced" />
              </Label>
            </FormGroup>
          </DropdownItem>
          <DropdownItem>
            <FormGroup check onClick={stopPropagationAndFlip(setIsIncompleteConfigurationEnabled)}>
              <Input type="checkbox" checked={isIncompleteConfigurationEnabled} />

              <Label check>
                <FormattedMessage id="HedgeElements.menu.incompleteConfig" />
              </Label>
            </FormGroup>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

      <HeaderTitle className="display-4 font-weight-bolder mx-auto">Fabulous TMS</HeaderTitle>

      <button className="btn btn-flat-black btn-lg">
        <HeaderIcon className="icon icon-lg mx-1">search</HeaderIcon>
      </button>

      <button className="btn btn-flat-black btn-lg">
        <HeaderIcon className="icon icon-lg">account_circle</HeaderIcon>
      </button>
    </div>
  );
}

const HeaderIcon = styled.i`
  color: #808080;
`;

const HeaderTitle = styled.div`
  color: #514b90;
`;

export default Header;
