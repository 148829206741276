import React, { useCallback, useState } from 'react';
import { NewCashForm } from './Cash/NewCashForm';
import { NewSwapForm } from './Swap/NewSwapForm';
import { datePlusTwo } from '../../mock/mock';
import { FormattedMessage } from 'react-intl';

export type Product = 'Spot' | 'Forward' | 'Swap';
export type Side = 'Buy' | 'Sell';

interface CoverFormProps {
  isDisplayedModal: boolean;
  toggleModal: () => void;
  submit: (values: any) => void;
}

export interface CashInputsValues {
  thirdPartyTradeReference?: string;
  product: Product;
  buyCurrency: string;
  sellCurrency: string;
  side: Side;
  rate?: string;
  amount: number;
  settlement?: string;
  sgReference?: string;
}

export interface SwapInputsValues {
  thirdPartyTradeReference?: string;
  product: 'SWAP';
  nearLeg: CashInputsValues;
  farLeg: CashInputsValues;
}

const products: Product[] = ['Spot', 'Forward', 'Swap'];

function getId() {
  return `TMS_${Date.now().toString()}`;
}

export function NewOperationForm({ isDisplayedModal, toggleModal, submit }: CoverFormProps) {
  const [selectedInstrument, setSelectedInstrument] = useState('Spot/Forward');

  const [cashFormValues, setCashFormValues] = useState<CashInputsValues>({
    product: products[0],
    sellCurrency: '',
    buyCurrency: '',
    side: 'Buy',
    amount: 0,
    settlement: datePlusTwo(),
  });
  const [swapFormValues, setSwapFormValues] = useState<SwapInputsValues>({
    product: 'SWAP',
    nearLeg: {
      product: products[0],
      buyCurrency: '',
      sellCurrency: '',
      side: 'Buy',
      amount: 0,
      settlement: datePlusTwo(),
    },
    farLeg: {
      product: products[1],
      buyCurrency: '',
      sellCurrency: '',
      side: 'Sell',
      amount: 0,
      settlement: datePlusTwo(),
    },
  });

  function getFormValues(operation: CashInputsValues | SwapInputsValues) {
    operation.product !== 'SWAP' ? setCashFormValues(operation) : setSwapFormValues(operation);
  }

  function submitHandler(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const target = event.currentTarget;
    const formValues = selectedInstrument !== 'Swap' ? cashFormValues : swapFormValues;

    submit({ ...formValues, thirdPartyTradeReference: getId() });
    setCashFormValues({
      ...formValues,
      product: products[0],
      side: 'Buy',
      settlement: datePlusTwo(),
    } as CashInputsValues);

    target.reset();
    toggleModal();
  }

  const onChangeInstrumentHandler = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedOption: string = event.target.value;
      setSelectedInstrument(selectedOption);
    },
    [selectedInstrument],
  );

  return (
    <div
      className={`modal fade ${isDisplayedModal ? 'show d-block' : ''}`}
      id="modal"
      role="dialog"
      aria-modal="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content shadow-max">
          <div className="modal-header">
            <h3 className="cover__title modal-title " id="exampleModalLabel4">
              <FormattedMessage id="HedgeCover.Title" />
            </h3>

            <button
              type="button"
              className="close icon"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                toggleModal();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body p-5">
            <select
              className="form-select mb-2"
              aria-label="Default select example"
              onChange={onChangeInstrumentHandler}
            >
              <FormattedMessage id="HedgeCover.ProductSpotForward">
                {option => <option value="Spot/Forward">{option}</option>}
              </FormattedMessage>
              <FormattedMessage id="HedgeCover.ProductSwap">
                {option => <option value="Swap">{option}</option>}
              </FormattedMessage>
            </select>

            <form onSubmit={submitHandler}>
              {selectedInstrument === 'Spot/Forward' && (
                <NewCashForm inputsValues={cashFormValues} setFormValues={getFormValues} />
              )}

              {selectedInstrument === 'Swap' && (
                <NewSwapForm inputsValues={swapFormValues} setFormValues={getFormValues} />
              )}

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-lg btn-link"
                  data-dismiss="modal"
                  onClick={() => {
                    toggleModal();
                  }}
                >
                  <FormattedMessage id="HedgeCover.Cancel" />
                </button>
                <button type="submit" className="btn btn-lg btn-primary" data-dismiss="modal">
                  <FormattedMessage id="HedgeCover.Confirm" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
