import React, { useEffect, useState } from 'react';
import { NewCashForm } from '../Cash/NewCashForm';
import { CashInputsValues, SwapInputsValues } from "../NewOperationForm";

interface SwapFormProps {
  inputsValues: SwapInputsValues;
  setFormValues: (values: SwapInputsValues) => void;
}

export function NewSwapForm({ inputsValues, setFormValues }: SwapFormProps) {
  const [legs, setLegs] = useState<SwapInputsValues>(inputsValues);

  useEffect(() => setFormValues(legs), [legs]);

  function getNearLegValues(leg: CashInputsValues) {
    const farleg = { ...legs.farLeg }
    setLegs({ ...legs, nearLeg: leg, farLeg: farleg });
  }

  function getFarLegValues(leg: CashInputsValues) {
    const nearLeg = { ...legs.nearLeg }
    setLegs({ ...legs, nearLeg: nearLeg, farLeg: leg });
  }

  return (
    <div>
      <h5 className="pl-2 mt-3 ">Aller</h5>
      <NewCashForm inputsValues={legs.nearLeg} setFormValues={getNearLegValues} />
      <h5 className="pl-2 ">Retour</h5>
      <NewCashForm inputsValues={legs.farLeg} setFormValues={getFarLegValues} />
    </div>
  );
}
