import { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { CashOperation } from './Cash/CashOperation';
import type { CashInputsValues, SwapInputsValues } from './NewOperationForm';
import { NewOperationForm } from './NewOperationForm';
import { SwapOperation } from './Swap/SwapOperation';
import { legMock } from '../../mock/mock';
import { FormattedMessage } from 'react-intl';
import { CollapsibleSection } from '../Layout/CollapsibleSection';
import { isDefined } from '@sgme/fp';
import { useIsAckOnProductPriced, useIsIncompleteConfiguration } from '@/context/contexts';
import { getConfig } from '@/config/config';

type Locale = 'en' | 'fr';
const defaultSupportedLocale: Locale = navigator.language.startsWith('fr') ? 'fr' : 'en';

function mapProductLeg(product: CashInputsValues) {
  const sideAmount = product.side === 'Buy' ? 'buyAmount' : 'sellAmount';
  return {
    thirdPartyTradeReference: product.thirdPartyTradeReference,
    product: product.product.toUpperCase(),
    sellCurrency: product.sellCurrency,
    buyCurrency: product.buyCurrency,
    [sideAmount]: product.amount,
    settlement: product.settlement,
    sgReference: product?.sgReference,
  };
}

interface LogMessage {
  id: number;
  message: string;
  items?: LogMessageItem[];
}

interface LogMessageItem {
  id: number;
  text: string;
}

export function OperationList() {
  const [isDisplayedModal, setIsDisplayedModal] = useState(false);
  const [isActionSuccessful, setIsActionSuccessful] = useState(false);
  const [allLogMessages, setLogMessages] = useState<LogMessage[]>([]);

  const isAckOnProductPricedEnabled = useIsAckOnProductPriced();
  const isIncompleteConfigurationEnabled = useIsIncompleteConfiguration();

  const [legs, setLegs] = useState<(CashInputsValues | SwapInputsValues)[]>(legMock);

  const spotLegs = useMemo(() => legs.filter(leg => leg.product === 'Spot'), [legs]);
  const forwardLegs = useMemo(() => legs.filter(leg => leg.product === 'Forward'), [legs]);
  const swapLegs = useMemo(() => legs.filter(leg => leg.product === 'SWAP'), [legs]);

  function toggleModal() {
    setIsDisplayedModal(!isDisplayedModal);
  }

  const logMessage = (message: string, items?: LogMessageItem[]) => {
    setLogMessages(allMessages => [...allMessages, { id: newId(), message, items }]);
  };

  const env = localStorage.getItem('env') ?? 'prod';

  useEffect(() => {
    const btn = document.querySelector<any>('#hedgeconnect-button');

    const params = new URLSearchParams(self.location.search);

    btn.user = params.has('user') ? params.get('user') ?? undefined : undefined;

    btn.getProduct = isIncompleteConfigurationEnabled
      ? undefined
      : () => {
          const productLegs = legs.map(productLeg => {
            if (productLeg.product !== 'SWAP') {
              return mapProductLeg(productLeg);
            } else {
              return {
                thirdPartyTradeReference: productLeg.thirdPartyTradeReference,
                product: 'SWAP',
                nearLeg: mapProductLeg(productLeg.nearLeg),
                farLeg: mapProductLeg(productLeg.farLeg),
              };
            }
          });

          return { bulkTrade: productLegs };
        };

    btn.onDealDone = (bulkLeg: any[]) => {
      setIsActionSuccessful(true);
      let legsWithInfo: any[] = [];
      const bulkLegItems: LogMessageItem[] = [];

      bulkLeg.forEach(leg => {
        const isBuyAmount = Boolean(leg?.buyAmount);
        const way = !isBuyAmount ? 'Sell' : 'Buy';
        const currency = way === 'Sell' ? leg.sellCurrency : leg.buyCurrency;

        let amount;

        if (way === 'Sell') {
          amount = leg.sellAmount;
        } else {
          amount = leg.buyAmount;
        }

        let newLegItem: CashInputsValues = {
          amount,
          buyCurrency: leg.buyCurrency,
          sellCurrency: leg.sellCurrency,
          product: leg.product,
          settlement: leg.settlement,
          side: way,
          thirdPartyTradeReference: leg.thirdPartyTradeReference,
          rate: leg.rate,
          sgReference: leg?.sgReference,
        };

        legsWithInfo.push(newLegItem);

        bulkLegItems.push({
          id: newId(),
          text: `${leg.product}: ${way} ${leg.contraAmount} ${currency} (rate: ${leg.rate})`,
        });
      });

      setLegs(legsWithInfo);

      logMessage('onDealDone called with', bulkLegItems);
    };

    btn.onProductPriced = (productId: string) => {
      logMessage(`onProductPriced called with ${productId} - ACK = ${isAckOnProductPricedEnabled}`);
      return isAckOnProductPricedEnabled; // ACK
    };

    btn.onDealNotDone = (param: any) => {
      logMessage(`onDealNotDone called with ${param}`);
    };

    btn.onClose = () => {
      logMessage(`The window has been closed`);
    };
  }, [legs, isAckOnProductPricedEnabled, isIncompleteConfigurationEnabled]);

  function removeLeg(operation: CashInputsValues | SwapInputsValues) {
    const filteredLegs = legs.filter(
      leg => leg.thirdPartyTradeReference !== operation.thirdPartyTradeReference,
    );
    setLegs(filteredLegs);
  }

  function getSubmitFormValues(leg: CashInputsValues) {
    setLegs([...legs, leg]);
  }

  const thirdPartyId = getConfig().tmsId;

  return (
    <div className="d-flex align-items-center">
      <div className="container-fluid mt-5" style={{ maxWidth: '1240px' }}>
        <div className=" container__header flex-between mr-5 ml-1 w-100">
          <h2 className="container__header--h2 pb-1 font-weight-bold">
            <FormattedMessage id="HedgeChange.Title" />
          </h2>

          <div className="d-flex mr-3">
            <button
              className="btn btn-flat-victoria btn-lg btn-icon-text mr-3"
              data-toggle="modal"
              data-target="#modal"
              onClick={toggleModal}
            >
              <i className="icon">add</i>
              <FormattedMessage id="HedgeChange.Add" />
            </button>
            <hedgeconnect-smartbutton
              id="hedgeconnect-button"
              environment={env}
              locale={defaultSupportedLocale}
              thirdPartyId={thirdPartyId} //thirdPartyId is handle by backend
            />
          </div>
        </div>

        {spotLegs.length > 0 && (
          <OperationListTitle className="fs-5 font-weight-bold mb-2 mt-4">
            <FormattedMessage id="HedgeElements.Spot" />
          </OperationListTitle>
        )}

        {spotLegs.length > 0 &&
          spotLegs.map(operation => (
            <CashOperation
              isActionSuccessful={isActionSuccessful}
              operation={operation as CashInputsValues}
              onClickHandle={() => removeLeg(operation)}
              key={operation.thirdPartyTradeReference}
            />
          ))}

        {forwardLegs.length > 0 && (
          <OperationListTitle className="fs-5 font-weight-bold mb-2 mt-4">
            <FormattedMessage id="HedgeElements.Forward" />
          </OperationListTitle>
        )}

        {forwardLegs.length > 0 &&
          forwardLegs.map(operation => (
            <CashOperation
              isActionSuccessful={isActionSuccessful}
              operation={operation as CashInputsValues}
              onClickHandle={() => removeLeg(operation)}
              key={operation.thirdPartyTradeReference}
            />
          ))}

        {swapLegs.length > 0 && (
          <OperationListTitle className="fs-5 font-weight-bold mb-2 mt-4">
            <FormattedMessage id="HedgeElements.Swap" />
          </OperationListTitle>
        )}

        {swapLegs.length > 0 &&
          swapLegs.map(operation => (
            <SwapOperation
              swap={operation as SwapInputsValues}
              onClickHandle={() => removeLeg(operation)}
              key={operation.thirdPartyTradeReference}
            />
          ))}

        <div id="app-messages" />

        <div>
          {legs.length == 0 && (
            <>
              <p>
                <FormattedMessage id="HedgeElement.NoCover" />
              </p>
            </>
          )}
        </div>

        <NewOperationForm
          isDisplayedModal={isDisplayedModal}
          toggleModal={toggleModal}
          submit={getSubmitFormValues}
        />

        <CollapsibleSection title="Logs">
          {allLogMessages.map(({ id, message, items }) => (
            <p key={id}>
              {message}
              {isDefined(items) && (
                <ul>
                  {items.map(item => (
                    <li key={item.id}>{item.text}</li>
                  ))}
                </ul>
              )}
            </p>
          ))}

          {allLogMessages.length === 0 && (
            <div>
              <FormattedMessage id="HedgeMapping.Logs.empty" />
            </div>
          )}
        </CollapsibleSection>
      </div>
    </div>
  );
}

const OperationListTitle = styled.p`
  color: #808080;
`;

let _nextId = 1;

const newId = () => _nextId++;
