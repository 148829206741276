import { useState } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import en from '../locales/en.json';
import fr from '../locales/fr.json';

export const locales = {
  en,
  fr,
};
type SupportedLocale = keyof typeof locales;
const params = new URLSearchParams(location.search);
const defaultSupportedLocale: SupportedLocale = params.has('lang')
  ? (params.get('lang') as SupportedLocale)
  : navigator.language.startsWith('fr')
  ? 'fr'
  : 'en';

export const IntlProvider = ({ children }: { children: JSX.Element }) => {
  const [locale] = useState(defaultSupportedLocale);

  return (
    <ReactIntlProvider locale={locale} messages={locales[locale]}>
      {children}
    </ReactIntlProvider>
  );
};
